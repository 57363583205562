@font-face {
  font-family: 'Averta';
  src: url('./fonts/Averta.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Averta';
  src: url('./fonts/Averta-Semibold.woff') format('woff');
  font-weight: 600;
  font-display: block;
}
@font-face {
  font-family: 'Averta';
  src: url('./fonts/Averta-Bold.woff') format('woff');
  font-weight: 700;
  font-display: block;
}
@font-face {
  font-family: 'Averta';
  src: url('./fonts/Averta-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-display: block;
}
