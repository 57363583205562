@import url('https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic&display=swap');

@font-face {
  font-family: 'San Francisco';
  src: url('./fonts/sf-ui-display-regular-webfont.woff2') format('woff2'),
    url('./fonts/sf-ui-display-regular-webfont.woff') format('woff'),
    url('./fonts/sf-ui-display-regular-webfont.ttf') format('truetype'),
    url('./fonts/sf-ui-display-regular-webfont.svg#sf_ui_displayregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco';
  src: url('./fonts/sf-ui-display-semibold-webfont.woff2') format('woff2'),
    url('./fonts/sf-ui-display-semibold-webfont.woff') format('woff'),
    url('./fonts/sf-ui-display-semibold-webfont.ttf') format('truetype'),
    url('./fonts/sf-ui-display-semibold-webfont.svg#sf_ui_displaysemibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco';
  src: url('./fonts/sf-ui-display-bold-webfont.woff2') format('woff2'),
    url('./fonts/sf-ui-display-bold-webfont.woff') format('woff'),
    url('./fonts/sf-ui-display-bold-webfont.ttf') format('truetype'),
    url('./fonts/sf-ui-display-bold-webfont.svg#sf_ui_displaybold') format('svg');
  font-weight: 700;
  font-style: normal;
}
